import { useEffect, useState } from "react"; // Import React hooks for managing component state and side effects
import { useLocation, useNavigate } from "react-router-dom"; // Import hooks for navigation and location management
import { useTranslation } from "react-i18next"; // Import hook for handling translations
import dayjs from "dayjs"; // Import utility library for working with dates

import { validateSearch } from "../../../utils/validation"; // Import function for validating search inputs
import useSearch from "../../../hooks/useSearch"; // Import custom hook for search functionality
import { useTypedSelector } from "../../../store/store";
import { useLazySearchLocationsQuery } from "../../../store/services/SearchService";

import SearchInput from "../../shared/SearchInput"; // Import component for search input
import DatePicker from "../../shared/DatePicker"; // Import component for date picker
import CustomSelect from "../../shared/CustomSelect"; // Import component for custom select input
import Button from "../../shared/Button"; // Import component for button

import styles from "./index.module.scss"; // Import styles for the Search component

// Define interface for input field
interface IInput {
  value: string; // Value of the input
  errorMessage?: string; // Error message for the input (optional)
}

// Define interface for date input field
interface IDateInput {
  value: string[]; // Array of date values
  errorMessage?: string; // Error message for the date input (optional)
}

// Define interface for input fields
interface IInputs {
  [key: string]: IInput | IDateInput; // Index signature for input objects
  country: IInput; // Input for country selection
  date: IDateInput; // Input for date selection
  duration: IInput; // Input for duration selection
}

// Define interface for validation of input fields
interface IValidationInputs {
  [key: string]: string; // Index signature for validation inputs
  country: string; // Validation for country input
  date: string; // Validation for date input
  duration: string; // Validation for duration input
}

// Define constant for night duration
const DURATION_ITEMS = [
  { value: "1", label: "1" }, // Option for duration of 1
  { value: "3-5", label: "3-5" }, // Option for duration of 3-5
  { value: "7", label: "7" }, // Option for duration of 7
  { value: "8-10", label: "8-10" }, // Option for duration of 8-10
  { value: "11-14", label: "11-14" }, // Option for duration of 11-14
  { value: "14+", label: "14+" }, // Option for duration of 14+
];

// Function to create array from range string
const createArrayFromRange = (rangeString: string) => {
  const [start, end] = rangeString.split("-").map(Number); // Destructure start and end values from range string

  // Create an array of numbers within the range
  const resultArray = [...Array(end - start + 1)].map(
    (_, index) => start + index,
  );

  return resultArray;
};

// Component for handling search functionality.
function Search() {
  const [searchLocations] = useLazySearchLocationsQuery();
  const navigate = useNavigate(); // Get navigation function from React Router
  const { t } = useTranslation(); // Get translation function from react-i18next

  const [lastDate, setLastDate] = useState("");

  const { destination_input_placeholder } = useTypedSelector(
    (state) => state.environment,
  );

  const { locations, searchHandlers, resetHandlers } = useSearch(); // Get search-related functions from custom hook

  const [chosenLocations, setChosenLocations] = useState<
    [] | Array<Record<string, string>>
  >([]); // State for chosen locations

  // State for form inputs
  const [inputs, setInputs] = useState<IInputs>({
    country: { value: "" }, // Initial state for country input
    // Initial state for date input
    date: {
      value: [], // Array to store selected dates
      errorMessage: "", // Error message for date input (if any)
    },
    duration: { value: "" }, // Initial state for duration input
  });

  const initLastDate = async () => {
    try {
      const { data } = await searchLocations("");

      if (data?.last_date) {
        setLastDate(data.last_date);
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : String(error);

      throw new Error(errorMessage);
    }
  };

  // Function to handle form errors.
  const handleFormError = (errors: IValidationInputs) => {
    // Create a deep copy of current inputs
    const updatedInputs: IInputs = structuredClone(inputs);

    // Iterate through validation errors
    Object.keys(errors).forEach((errorKey) => {
      // Check if input exists for the error key
      if (updatedInputs[errorKey]) {
        // Update input's error message
        updatedInputs[errorKey].errorMessage = errors[errorKey];
      }
    });

    // Update state with modified inputs
    setInputs(updatedInputs);
  };

  // Function to search for locations.
  const searchLocation = async (value: string) => {
    // Check if the search value is provided
    if (value) {
      // Call location search function
      await searchHandlers.locationSearch(value);
    } else {
      // Reset location search
      resetHandlers.locationReset();
    }
  };

  // Function to handle input change.
  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string | string[]; // Value of the input
    valueKey?: string; // Key corresponding to the input (optional)
  }) => {
    if (valueKey) {
      // Update inputs state
      setInputs((prev: IInputs) => {
        return {
          ...prev,
          [valueKey]: {
            errorMessage: "",
            value: Array.isArray(value) ? value : [value],
          },
        };
      });
    }

    if (valueKey === "country") {
      // Initiate location search
      searchLocation(value as string);
    }
  };

  // Function to handle chosen locations change
  const handleChosenLocationsChange = ({
    items,
  }: {
    items?: Array<Record<string, string>>;
  }) => setChosenLocations(items ?? []);

  // Function to save search parameters to local storage
  const handleSaveSearch = (payload: {
    duration: string;
    date: string[];
    chosenLocations: [] | Array<Record<string, string>>;
  }) => {
    // Save search parameters to local storage
    localStorage.setItem("search", JSON.stringify({ ...payload }));
  };

  // Function to handle search.
  const handleSearch = () => {
    const night = (() => {
      switch (true) {
        case inputs.duration.value[0]?.includes("-"): {
          const rangeArray = createArrayFromRange(inputs.duration.value[0]);

          return rangeArray.join("-");
        }
        case inputs.duration.value[0]?.includes("+"): {
          return inputs.duration.value[0].replace("+", "");
        }
        default: {
          return inputs.duration.value[0];
        }
      }
    })();

    const { country, ship, tag } = (() => {
      const output: Record<string, string[]> = {
        country: [],
        ship: [],
        cruise_tag: [],
      };

      if (chosenLocations.length) {
        chosenLocations.forEach((location) => {
          const { type } = location;

          output[type].push(location.value);
        });
      }

      return {
        country: output.country.join("-"),
        ship: output.ship.join("-"),
        tag: output.cruise_tag.join("-"),
      };
    })();

    const payload: Record<string, string> = {
      night,
      country,
      ship,
      tag,
    };

    inputs.date.value.forEach((date, index) => {
      const dateKey = index === 0 ? "start_date" : "end_date";

      payload[dateKey] = date;
    });

    handleSaveSearch({
      duration: inputs.duration.value[0],
      date: inputs.date.value,
      chosenLocations,
    });

    const searchParams = new URLSearchParams();

    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        searchParams.append(key, value);
      }
    });

    const newSearch = searchParams.toString();

    navigate(`/search-results?${newSearch}`);
  };

  // Function to handle form submission.
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission

    // Validate search inputs
    validateSearch({
      // Data object containing form inputs
      data: {
        country: inputs.country.value, // Country input value
        start_date: inputs.date.value[0], // Start date value
        end_date: inputs.date.value[1], // End date value
        duration: inputs.duration.value, // Duration value
      },
      // Callback function for successful validation
      onSuccess: handleSearch,
      // Callback function for validation errors
      onError: (errors: IValidationInputs) => {
        const clonedErrors = structuredClone(errors); // Create a deep copy of validation errors

        // Check if start or end date has errors
        if (clonedErrors.start_date || clonedErrors.end_date) {
          clonedErrors.date = clonedErrors.start_date ?? clonedErrors.end_date; // Assign date error

          delete clonedErrors.start_date; // Delete start date error
          delete clonedErrors.end_date; // Delete end date error
        }

        // Handle form errors
        handleFormError(clonedErrors);
      },
    });
  };

  // Get current location from React Router
  const location = useLocation();

  // Effect hook to run on component mount and location change
  useEffect(() => {
    // Check if search parameters exist in local storage or location is not for editing
    if (!localStorage.getItem("search") || location.search !== "?edit") {
      return;
    }

    // Parse search parameters from local storage
    const search = JSON.parse(localStorage.getItem("search") ?? "");

    // Set chosen locations
    setChosenLocations(search?.chosenLocations ?? []);

    // Set changes from input
    handleInputChange({
      value: search?.duration ? [search?.duration] : [],
      valueKey: "duration",
    });

    // Set changes from input
    handleInputChange({
      value: search?.date ?? [],
      valueKey: "date",
    });
  }, [location]);

  useEffect(() => {
    (async () => {
      await initLastDate();
    })();
  }, []);

  // Return JSX
  return (
    // Container for the search component
    <div className={styles.container}>
      {/* Content section */}
      <div className={styles.content}>
        {/* Title for the search component */}
        <h1 className={styles.title}>{t("start your search")}</h1>

        {/* Form for search inputs */}
        <form className={styles.form} onSubmit={handleSubmit}>
          {/* Container for search input */}
          <div className={styles.searchInputContainer}>
            {/* Search input component */}
            <SearchInput
              changes={chosenLocations}
              value={inputs.country.value}
              valueKey="country"
              displayKey="name"
              results={locations.results}
              placeholder={
                destination_input_placeholder ||
                t("Type for ships, countries, companies")
              }
              errorMessage={inputs.country.errorMessage}
              isLoading={locations.isLoading}
              onChange={handleInputChange}
              onChosenChange={handleChosenLocationsChange}
              isMultiple
              showError
            />
          </div>

          {/* Container for date picker */}
          <div className={styles.selectDateContainer}>
            <DatePicker
              placeholder={t("Select date")}
              defaultDate={inputs.date.value}
              valueKey="date"
              returnFormat="YYYY-MM"
              minDate={dayjs().startOf("month")}
              maxDate={dayjs(dayjs(lastDate).toDate()).startOf("month")}
              mode="range"
              errorMessage={inputs.date.errorMessage}
              onChange={handleInputChange}
            />
          </div>

          {/* Container for duration select */}
          <div className={styles.durationContainer}>
            <CustomSelect
              value={inputs.duration.value[0] ?? ""}
              valueKey="duration"
              placeholder={t("Duration")}
              errorMessage={inputs.duration.errorMessage}
              items={DURATION_ITEMS}
              onChange={handleInputChange}
            />
          </div>

          {/* Container for search button */}
          <div className={styles.searchButtonContainer}>
            <Button
              label={t("search")}
              type="submit"
              className={styles.button}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

// Export Search component
export default Search;
