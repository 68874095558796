import { useEffect } from "react"; // Importing the useEffect hook from React for handling side effects.
import { useParams, useSearchParams } from "react-router-dom"; // Hooks for navigation and accessing URL search params.

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Importing hooks for typed dispatch and selector from Redux store.
import { useLazyInitCruiseQuery } from "../../../store/services/SearchService"; // Importing a custom hook for lazy initialization of cruise query from search service.
import { ICruise } from "../../../store/slices/searchSlice"; // Importing interface for cruise from search slice.
import { RoomsState, updateRoomsState } from "../../../store/slices/roomsSlice"; // Importing rooms state and action creators from rooms slice.
import { createArrayRange } from "../../../utils/helpers/createArrayRange"; // Importing helper function to create an array range.

import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs"; // Importing custom breadcrumbs component.
import SearchBar from "../../base/SearchBar"; // Importing search bar component.

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Array of available market types.
const AVAILABLE_MARKETS: Array<keyof ICruise["markets"]> = [
  "inside",
  "outside",
  "balcony",
  "suite",
];

// Functional component for search layout.
function SearchLayout({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch(); // Typed dispatch hook from Redux store.
  const [getCruise] = useLazyInitCruiseQuery(); // Lazy initialization hook for cruise query.

  const { cruiseId } = useParams(); // Retrieving cruise ID from URL parameters.
  const [searchParams] = useSearchParams(); // Retrieving search parameters from URL.

  const { cruise } = useTypedSelector((state) => state.search); // Selecting cruise state from Redux store.
  const { rooms } = useTypedSelector((state) => state.rooms); // Selecting rooms state from Redux store.

  const totalRooms = +(searchParams.get("rooms") ?? 0); // Total number of rooms from URL parameters.
  const source = searchParams.get("source") ?? ""; // Source of the search from URL parameters.

  // Function to initialize cruise data.
  const initCruise = () => {
    getCruise({ cruiseId: cruiseId as string, source });
  };

  // Function to initialize blank staterooms.
  const initBlankStaterooms = () => {
    const roomsRange = createArrayRange(1, totalRooms); // Creating an array range for rooms.

    // Finding the default market type based on availability.
    // Finding the default market type based on availability.
    const defaultMarketType = AVAILABLE_MARKETS.find((mKey) => {
      const market = cruise?.markets[mKey];

      return (
        market &&
        typeof market === "object" &&
        "price" in market &&
        market.price !== "0"
      );
    });

    // Initial state for rooms.
    const initialRoomsState: RoomsState = {
      rooms: {},
      cabins: undefined,
      isCabinsLoading: true,
    };

    // Iterating through room numbers.
    roomsRange.forEach((num) => {
      // Retrieving room data if available.
      const room = rooms?.[num] ?? {};

      const paramsFare = searchParams.get(`fare_${num}`); // Retrieving fare parameter from URL.
      const paramsGrade = searchParams.get(`grade_${num}`); // Retrieving grade parameter from URL.
      const paramsGuests = searchParams.get(`guests_${num}`); // Retrieving guests parameter from URL.
      const paramsMarket = searchParams.get(`market_${num}`); // Retrieving market parameter from URL.

      // Finding fare based on parameters.
      const fare = cruise?.pricing.find(
        (el) => el.rate_code === paramsFare || el.lowest_fare,
      );

      const grade = cruise?.ship.grades.find((el) => el.code === paramsGrade); // Finding grade based on parameters.
      const guestsNumber = paramsGuests ? +paramsGuests : undefined; // Converting guests parameter to number.
      const marketType = paramsMarket; // Setting market type based on parameter.

      // Setting initial room state.
      initialRoomsState.rooms![num] = {
        fare: fare ?? cruise?.pricing[0],
        grade,
        guestsNumber,
        marketType: marketType ?? defaultMarketType,
        pricing: room.pricing ?? {},
        ...room,
      };
    });

    dispatch(updateRoomsState(initialRoomsState)); // Dispatching action to update rooms state.
  };

  // Effect for initializing cruise data.
  useEffect(() => {
    if (cruiseId) {
      initCruise();
    }
  }, [cruiseId]);

  // Effect for initializing blank staterooms.
  useEffect(() => {
    if (cruise) {
      initBlankStaterooms();
    }
  }, [cruise, totalRooms]);

  return (
    <main className={styles.container}>
      {/* Main container for search layout. */}
      {/* Search bar component. */}
      <SearchBar />

      {/* Content section. */}
      <section className={styles.content}>
        {/* Custom breadcrumbs component. */}
        <CustomBreadcrumbs />

        {/* Rendered children components. */}
        <div className={styles.children}>{children}</div>
      </section>
    </main>
  );
}

// Exporting the SearchLayout component for use
export default SearchLayout;
