import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react"; // Importing necessary modules from React library

import { createArrayRange } from "../../../../../utils/helpers/createArrayRange"; // Importing createArrayRange function from specified path
import { GuestFields } from "../../../../../store/slices/guestsSlice"; // Importing GuestFields type from specified path

import RoomForm from "../RoomForm"; // Importing RoomForm component from specified path

import styles from "./index.module.scss"; // Importing CSS modules

// Defining props interface for RoomForms component
interface IRoomFormsProps {
  isIncludesLead: boolean; // Indicates if the room includes the lead guest
  roomNumber: number; // Number of the room
  guestsAmount: number; // Number of guests in the room
}

// Type for the output of validateForms method
type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields; // Inputs of the guest
      errors: GuestFields | null; // Errors related to the inputs
    }
  >
>;

// Interface for the RoomFormsRef, representing methods exposed by RoomForms component
interface RoomFormsRef {
  validateForms: () => ValidateFormsOutput;
}

/**
 * Represents a component for managing multiple RoomForm instances.
 * This component renders RoomForm components for each guest in a room.
 *
 * @param {boolean} isIncludesLead - Indicates if the room includes the lead guest.
 * @param {number} roomNumber - The number of the room.
 * @param {number} guestsAmount - The number of guests in the room.
 * @param {ForwardedRef<RoomFormsRef>} ref - The forwarded ref object for exposing methods.
 *
 * @returns {JSX.Element} - Returns the JSX element representing the RoomForms component.
 *
 * @typedef {Object} RoomFormsRef - An interface representing methods exposed by the RoomForms component.
 * @property {Function} validateForms - A method to validate all RoomForm instances and return the validation results.
 * @typedef {Object} ValidateFormsOutput - Represents the output of the validateForms method.
 * @property {Object} roomNumber - The room number.
 * @property {Object} guestNumber - The guest number.
 * @property {Object} inputs - The inputs of the guest.
 * @property {Object | null} errors - The errors related to the inputs, or null if no errors.
 */
const RoomForms = forwardRef<RoomFormsRef, IRoomFormsProps>(function RoomForms(
  { isIncludesLead, roomNumber, guestsAmount }: IRoomFormsProps,
  ref: ForwardedRef<RoomFormsRef>,
) {
  // Memoizing the range of guest numbers using useMemo
  const guestsRange = useMemo(() => createArrayRange(1, guestsAmount ?? 1), []);

  // Mutable refs for each RoomForm component
  const roomFormsRefs: Record<
    number,
    MutableRefObject<{
      expand: () => void;
      collapse: () => void;
      scrollIntoView: () => void;
      validateForm: () => {
        inputs: GuestFields;
        errors: GuestFields | null;
      };
    } | null>
  > = {};

  // Method to validate forms in all rooms
  const validateForms = () => {
    const output: ValidateFormsOutput = { [roomNumber]: {} };

    // Iterating over roomFormsRefs to validate forms
    Object.values(roomFormsRefs).forEach((formRef, index) => {
      const validationResult = formRef.current?.validateForm();

      // If validation result exists, add it to the output
      if (validationResult) {
        output[roomNumber][index + 1] = {
          ...formRef.current,
          ...validationResult,
        };
      }
    });

    return output;
  };

  // Exposing validateForms method using useImperativeHandle
  useImperativeHandle(ref, () => ({ validateForms }), []);

  // Rendering RoomForm components for each guest in the room
  return (
    <div className={styles.container}>
      {guestsRange.map((guestNumber) => {
        const guestRef = useRef(null);

        roomFormsRefs[guestNumber] = guestRef;

        return (
          <RoomForm
            key={guestNumber}
            ref={guestRef}
            isLead={isIncludesLead && guestNumber === 1}
            guestNumber={guestNumber}
            roomNumber={roomNumber}
          />
        );
      })}
    </div>
  );
});

// Exporting RoomForms component
export default RoomForms;
