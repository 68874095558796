import { Oval } from "react-loader-spinner"; // Import Oval component from react-loader-spinner library

import { useTypedSelector } from "../../../store/store"; // Import Redux store hook

// Interface defining props for LoadingIndicator component
interface ILoadingIndicatorProps {
  width?: number; // Optional: The width of the loading indicator
  height?: number; // Optional: The height of the loading indicator
  strokeWidth?: number; // Optional: The stroke width of the loading indicator
  strokeWidthSecondary?: number; // Optional: The secondary stroke width of the loading indicator
  className?: string; // Optional: Additional CSS class names for styling
}

/**
 * LoadingIndicator component for displaying a loading animation.
 *
 * @param {number} width - The width of the loading indicator.
 * @param {number} height - The height of the loading indicator.
 * @param {string} color - The primary color of the loading indicator.
 * @param {string} secondaryColor - The secondary color of the loading indicator.
 * @param {number} strokeWidth - The stroke width of the loading indicator.
 * @param {number} strokeWidthSecondary - The secondary stroke width of the loading indicator.
 * @param {string} className - Additional CSS class names for styling.
 * @returns {JSX.Element} The LoadingIndicator component.
 */
function LoadingIndicator({
  width,
  height,
  strokeWidth,
  strokeWidthSecondary,
  className,
}: ILoadingIndicatorProps) {
  // Access primary_color from Redux store
  const { primary_color } = useTypedSelector((state) => state.environment);

  return (
    <Oval
      ariaLabel="oval-loading"
      width={width} // Set the width of the loading indicator
      height={height} // Set the height of the loading indicator
      color={primary_color || "#000000"} // Set the primary color of the loading indicator
      secondaryColor={primary_color || "#000000"} // Set the secondary color of the loading indicator
      strokeWidth={strokeWidth} // Set the stroke width of the loading indicator
      strokeWidthSecondary={strokeWidthSecondary} // Set the secondary stroke width of the loading indicator
      wrapperClass={className} // Set additional CSS class names for styling
    />
  );
}

// Default props for LoadingIndicator component
LoadingIndicator.defaultProps = {
  strokeWidth: 8,
  strokeWidthSecondary: 4,
  width: 40,
  height: 40,
  className: "",
};

// Export the LoadingIndicator component as default
export default LoadingIndicator;
