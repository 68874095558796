/**
 * Validates a credit card number by checking its length and convertibility to a number.
 * @param {string} cardNumber - The credit card number to validate.
 * @returns {boolean | string} True if the credit card number is valid, otherwise an error message.
 */
export const validateCreditCard = (cardNumber) => {
  const cardWithoutSpaces = cardNumber.replace(/\s/g, "");

  const isValidLength =
    cardWithoutSpaces.length === 15 || cardWithoutSpaces.length === 16;

  const isConvertibleToNumber = !Number.isNaN(Number(cardWithoutSpaces));

  if (!isValidLength) {
    return "Card number length is not valid";
  }

  if (!isConvertibleToNumber) {
    return "Card number is not a number";
  }

  return true;
};

/**
 * Validates a CVV number by checking its format.
 * @param {string} cvv - The CVV number to validate.
 * @returns {boolean | string} True if the CVV number is valid, otherwise an error message.
 */
export const validateCVV = (cvv) => {
  const cvvPattern = /^[0-9]{3,4}$/;

  if (!cvvPattern.test(cvv)) {
    return "CVV format is not valid";
  }

  return true;
};

/**
 * Validates a date string in the format "YYYY-MM" by checking its pattern and components.
 * @param {string} dateString - The date string to validate.
 * @returns {boolean | string} True if the date string is valid, otherwise an error message.
 */
export const validateDate = (dateString) => {
  const datePattern = /^(20\d{2})-(0[1-9]|1[0-2])$/;

  if (!datePattern.test(dateString)) {
    return "Date format is not valid";
  }

  const [year, month] = dateString.split("-");

  if (
    parseInt(year, 10) < 2000 ||
    parseInt(month, 10) < 1 ||
    parseInt(month, 10) > 12
  ) {
    return "Date components are not valid";
  }

  return true;
};
