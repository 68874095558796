import { ChangeEvent, useCallback, useEffect, useState, useRef } from "react"; // Import necessary hooks and components from React
import classNames from "classnames"; // Import classNames library for conditional CSS class assignment

import styles from "./index.module.scss"; // Import component styles

// Define props interface for the RangeSlider component
interface IRangeSliderProps {
  min: number; // Minimum value for the range slider
  max: number; // Maximum value for the range slider
  onChange?: (value: { min: number; max: number }) => void; // Function to handle onChange event
  onMouseUp?: (value: { min: number; max: number }) => void; // Function to handle onMouseUp event
  showLabel?: boolean; // Boolean indicating whether to show label values
  className?: string; // Custom CSS class name for the component
}

/**
 * Represents a range slider component.
 *
 * @param {number} min - The minimum value of the slider.
 * @param {number} max - The maximum value of the slider.
 * @param {function} onChange - Callback function triggered when the slider value changes.
 * @param {function} onMouseUp - Callback function triggered when the mouse is released after dragging the slider.
 * @param {boolean} [showLabel=false] - Indicates whether to show the label values for min and max values.
 * @param {string} [className] - Additional CSS class name(s) for the slider container.
 * @returns {JSX.Element} RangeSlider component.
 */
function RangeSlider({
  min,
  max,
  onChange,
  onMouseUp,
  showLabel,
  className,
}: IRangeSliderProps) {
  // State for storing data
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);

  // Ref for storing the minimum value of the range slider
  const minValRef = useRef(min);

  // Ref for storing the maximum value of the range slider
  const maxValRef = useRef(max);

  // Ref for accessing the range slider DOM element
  const range = useRef<HTMLDivElement>(null);

  // Function to calculate percentage value based on the range
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  // Effect to set width of the range slider based on minVal
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Effect to set width of the range slider based on maxVal
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  return (
    <div className={classNames(styles.container, className)}>
      {/* Input element for the left thumb of the range slider */}
      <input
        className={classNames(styles.thumb, styles.thumb__left)}
        style={{ zIndex: minVal > max - 100 ? 5 : undefined }}
        type="range"
        min={min}
        max={max}
        value={minVal}
        onMouseUp={() => {
          if (onMouseUp) {
            onMouseUp({ min: minVal, max: maxVal });
          }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);

          minValRef.current = value;
          setMinVal(value);
          if (onChange) {
            onChange({ min: value, max: maxVal });
          }
        }}
      />

      {/* Input element for the right thumb of the range slider */}
      <input
        className={classNames(styles.thumb, styles.thumb__right)}
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onMouseUp={() => {
          if (onMouseUp) {
            onMouseUp({ min: minVal, max: maxVal });
          }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(Number(event.target.value), minVal + 1);

          maxValRef.current = value;
          setMaxVal(value);
          if (onChange) {
            onChange({ min: minVal, max: value });
          }
        }}
      />

      {/* Slider track and range */}
      <div className={classNames(styles.slider)}>
        <div className={classNames(styles.slider__track)} />
        <div ref={range} className={classNames(styles.slider__range)} />

        {/* Render label values if showLabel prop is true */}
        {showLabel && (
          <>
            <div className={classNames(styles.slider__left__value)}>
              {minVal}
            </div>
            <div className={classNames(styles.slider__right__value)}>
              {maxVal}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

// Default props for the RangeSlider component
RangeSlider.defaultProps = {
  showLabel: false,
  className: "",
  onChange: () => {},
  onMouseUp: () => {},
};

// Export the RangeSlider component as the default export
export default RangeSlider;
