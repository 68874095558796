import { useCallback, useEffect, useState } from "react"; // Import React hooks
import Breadcrumbs from "@mui/material/Breadcrumbs"; // Import Breadcrumbs component from Material-UI
import { t } from "i18next"; // Import translation function from i18next

// Import React Router hooks
import {
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import router from "../../../utils/constants/router"; // Import router configuration
import { useTypedSelector } from "../../../store/store"; // Import Redux store hook

import CustomLink from "../CustomLink"; // Import custom Link component

import styles from "./index.module.scss"; // Import component styles

/**
 * Retrieves the search parameters relevant to the current crumb.
 *
 * @param {string[]} crumbParams - Parameters associated with the crumb.
 * @param {URLSearchParams} searchParams - URL search parameters.
 * @returns {string} Formatted search parameters.
 */
const getCrumbSearchParams = (
  crumbParams: string[],
  searchParams: URLSearchParams,
) => {
  let output = "";

  searchParams.forEach((value, key) => {
    const firstChar = output.length === 0 ? "?" : "&";
    const shouldParamBeUsed = crumbParams.some((crumb) =>
      key.startsWith(crumb.replace("*", "")),
    );

    if (shouldParamBeUsed) {
      output += `${firstChar}${key}=${value}`;
    }
  });

  return output;
};

// Custom breadcrumbs component.
function CustomBreadcrumbs() {
  const [searchParams] = useSearchParams(); // Retrieve search parameters from the URL
  const params = useParams(); // Retrieve URL parameters
  const { pathname, search } = useLocation(); // Retrieve current URL location

  const { breadcrumbs_text } = useTypedSelector((state) => state.environment); // Retrieve breadcrumbs text from Redux store

  const [crumbs, setCrumbs] = useState<Record<string, { title: string }>>({}); // State for storing breadcrumb titles

  // Filter router entries to those with titles
  const routesEntries = Object.entries(router).filter(
    ([, route]) => !!route.title,
  );

  // Find index of the current route
  const currentRouteIndex = routesEntries.findIndex(([path]) =>
    matchPath(path, pathname),
  );

  // Initialize breadcrumbs
  const initCrumbs = useCallback(() => {
    const slicedRouteEntries = routesEntries.slice(0, currentRouteIndex + 1); // Slice router entries up to the current route
    const newCrumbs: Record<string, { title: string }> = {}; // Initialize new breadcrumb object

    // Iterate through sliced route entries
    slicedRouteEntries.forEach(([routeKey, route]) => {
      const routeParam = routeKey.split(":")[1]?.split("/")[0]; // Extract route parameter

      // Replace route parameter with actual value
      const crumbPathname = routeKey.replace(
        `:${routeParam}`,
        params[routeParam] ?? "",
      );

      // Get formatted search parameters for the crumb
      const crumbSearchParams = getCrumbSearchParams(
        route.crumbParams ?? [],
        searchParams,
      );

      // Concatenate pathname with search parameters
      const pathnameWithSearch = `${crumbPathname}${crumbSearchParams}`;

      // Add crumb to the breadcrumb object
      newCrumbs[pathnameWithSearch] = { title: route.title ?? "" };
    });

    // Update state with the new breadcrumb object
    setCrumbs(newCrumbs);
  }, [pathname, search]);

  // Initialize breadcrumbs on component mount and when pathname or search changes
  useEffect(initCrumbs, [pathname, search]);

  return (
    <div className={styles.container}>
      <Breadcrumbs>
        {Object.entries(crumbs)
          .slice(0, currentRouteIndex + 1)
          .map(([crumbKey, crumb], index) => {
            // Determine if crumb is the last one
            const isLast =
              index ===
              Object.entries(crumbs).slice(0, currentRouteIndex + 1).length - 1;

            return (
              <CustomLink
                key={crumbKey}
                to={crumbKey}
                isPrimary={isLast}
                isDisabled={isLast}>
                {crumb.title}
              </CustomLink>
            );
          })}
      </Breadcrumbs>

      {breadcrumbs_text && <p className={styles.text}>{t(breadcrumbs_text)}</p>}
    </div>
  );
}

// Export the CustomBreadcrumbs component
export default CustomBreadcrumbs;
