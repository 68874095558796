// Import necessary dependencies and components.
import { useMemo } from "react"; // React hook for memoizing values.
import { useNavigate, useSearchParams } from "react-router-dom"; // Hooks for navigation and accessing URL search params.
import { useTranslation } from "react-i18next"; // Hook for translation.
import classNames from "classnames"; // Utility for conditionally joining classNames.

import { Grade } from "../../../../../store/slices/searchSlice"; // Import Grade type.
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store"; // Custom hooks to access typed dispatch and selectors from the store.
import { updateRoom } from "../../../../../store/slices/roomsSlice"; // Action for updating room details in the store.
import { hideModal } from "../../../../../store/slices/modalSlice"; // Action for hiding modal in the store.
import { prepareParams } from "../../../../../utils/helpers/rooms"; // Function for preparing search params.
import { currencyToFormat } from "../../../../../utils/helpers/currency"; // Function for formatting currency.

import CustomCarousel from "../../../../shared/Carousel"; // Reusable component for carousel display.
import Button from "../../../../shared/Button"; // Reusable component for button.

import styles from "./index.module.scss"; // Styles specific to StateroomsModal component.

// StateroomsModal component responsible for rendering a modal displaying stateroom details.
function StateroomsModal() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Retrieve necessary data from the store using useTypedSelector hook.
  const [searchParams] = useSearchParams();
  const { cruise } = useTypedSelector((state) => state.search);
  const { data } = useTypedSelector((state) => state.modal);
  const { rooms } = useTypedSelector((state) => state.rooms);

  const grade = data as Grade; // Cast data as Grade type.
  const totalRooms = +(searchParams.get("rooms") ?? 0); // Retrieve total number of rooms from URL search params.
  const currentRoomNumber = +(searchParams.get("room") ?? 0); // Retrieve current room number from URL search params.

  // Memoized room based on current room number.
  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }

    return undefined;
  }, [rooms, currentRoomNumber]);

  // Memoized boolean indicating whether navigation forward is possible.
  const canGoForward = useMemo(() => {
    return !!room?.grade && !!room?.guestsNumber;
  }, [room]);

  // Function to handle updating chosen grades.
  const handleUpdateChosenGrades =
    (incomingGrade: Grade, guestsNumber: number) => () => {
      const updatedStateroom = structuredClone({
        ...room,
        incomingGrade,
        guestsNumber,
      });

      dispatch(
        updateRoom({ room: updatedStateroom, roomNumber: currentRoomNumber }),
      );
    };

  // Function to handle form submission.
  const handleSubmit = () => {
    const pathParam = currentRoomNumber < totalRooms ? "rooms" : "cabin-select";

    const params = prepareParams(
      searchParams,
      rooms ?? {},
      currentRoomNumber,
      currentRoomNumber < totalRooms,
    );

    if (cruise) {
      navigate(`/search-results/${cruise.code}/${pathParam}?${params}`);
    }

    dispatch(hideModal());
  };

  // Function to get button label based on current room number and total rooms
  const getButtonLabel = () => {
    if (currentRoomNumber < totalRooms) {
      return `continue to stateroom ${currentRoomNumber + 1}`;
    }

    return "continue to staterooms";
  };

  // Render the StateroomsModal component.
  return (
    <div className={styles.container}>
      {/* Render custom carousel with stateroom grade images */}
      <CustomCarousel items={grade.images} />

      {/* Render stateroom grade name */}
      <p className={styles.name}>{grade.name}</p>

      {/* Render stateroom grade details */}
      <div className={styles.row}>
        <div className={styles.cell}>
          <span className={styles.cellKey}>located:</span>

          <span className={styles.cellValue}>{`Deck ${grade.decks
            .map((el) => el.code)
            .join(", ")}`}</span>
        </div>

        <div className={styles.cell}>
          <span className={styles.cellKey}>occupancy:</span>

          {/* Render stateroom grade description */}
          <span
            className={
              styles.cellValue
            }>{`Up to ${grade.maximum_occupancy} guests`}</span>
        </div>
      </div>

      {/* Render stateroom grade facilities */}
      <p className={styles.cellValue}>
        {grade.descriptions?.[0].description ?? ""}
      </p>

      {/* Render stateroom grade pricing per guest */}
      <div className={styles.block}>
        <p className={styles.cellKey}>facilities</p>

        <ul>
          <li className={styles.cellValue}>
            <span>{grade.size}</span>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <p className={styles.cellKey}>pricing per guest</p>

        <div className={styles.grades}>
          {[1, 2, 3, 4].map((guestsNumber) => {
            const { single, double, triple, quadruple } = grade;
            const guestTitle = guestsNumber === 1 ? t("guest") : t("guests");

            const pricesByGuestNumber: Record<number, number> = {
              1: single,
              2: double,
              3: triple,
              4: quadruple,
            };

            const price =
              guestsNumber > grade.maximum_occupancy ||
              guestsNumber < grade.minimum_occupancy
                ? "$N/A"
                : currencyToFormat(
                    pricesByGuestNumber[guestsNumber],
                    cruise?.pricing[0].currency ?? "USD",
                  ).slice(0, -3);

            const isDisabled = price === "$N/A";

            const isActive =
              room?.grade?.code === grade.code &&
              room?.guestsNumber === guestsNumber;

            return (
              <div
                key={guestsNumber}
                role="button"
                tabIndex={0}
                className={classNames(styles.grade, {
                  [styles.grade_active]: isActive,
                  [styles.grade_disabled]: isDisabled,
                })}
                onClick={
                  !isDisabled
                    ? handleUpdateChosenGrades(grade, guestsNumber)
                    : () => {}
                }
                onKeyPress={(e) => {
                  if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
                    handleUpdateChosenGrades(grade, guestsNumber);
                  }
                }}>
                <p
                  className={
                    styles.gradeName
                  }>{`${guestsNumber} ${guestTitle}`}</p>

                <p className={styles.gradePrice}>{`${price ?? 0}`}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Render button for navigation */}
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          label={getButtonLabel()}
          disabled={!canGoForward}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

// Export the StateroomsModal component for use in other parts of the application.
export default StateroomsModal;
