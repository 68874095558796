// Import necessary dependencies from React.
import { useEffect, useState } from "react"; // Hooks for managing component lifecycle and state.
import classNames from "classnames"; // Library for easily adding CSS classes.
import dayjs from "dayjs"; // Library for working with dates.
import { useTranslation } from "react-i18next"; // Hook for internationalization (i18n).

// Import constant ROOT for accessing the root route.
import { ROOT } from "../../../utils/constants/routes";

// Import CustomLink component for creating custom links.
import CustomLink from "../../shared/CustomLink";

// Import styles for the component.
import styles from "./index.module.scss";

// Import locales for the dayjs library.
import "dayjs/locale/es";
import "dayjs/locale/en";

// Interface representing the structure of data stored in local storage related to search.
interface ISearchLocalStorage {
  duration: string | null; // Duration of stay.
  date: string[]; // Dates.
  chosenLocations: [] | Array<Record<string, string>>; // Chosen locations.
}

// Component FormatSearchString formats the search string.
function FormatSearchString({
  chosenLocations,
  date,
  duration,
}: ISearchLocalStorage) {
  const { t } = useTranslation(); // Initialize i18n hook.

  // Format dates into strings for display in the component.
  let datesFormat = date
    ?.filter((dateToFormat) => dateToFormat)
    .map((dateToFormat) => dayjs(dateToFormat).format("MMMM"));

  datesFormat = [...new Set(datesFormat)];

  // Render the search string with chosen locations, dates, and duration.
  return (
    <p>
      {t("You searched ")}
      {chosenLocations?.map((location, index) => (
        <strong key={location.name}>
          {!!index && " , "}
          {location.name}
        </strong>
      ))}
      {t(" in ")}
      {datesFormat?.map((dateFormat, index) => (
        <strong key={dateFormat}>
          {!!index && " - "}
          {dateFormat}
        </strong>
      ))}
      {duration && (
        <span>
          {" "}
          for <strong>{duration} nights</strong>
        </span>
      )}
    </p>
  );
}

// Component SearchBar renders the search bar and edit search link.
function SearchBar() {
  const { t } = useTranslation(); // Initialize i18n hook.

  // State to store data from local storage regarding the search.
  const [data, setData] = useState<ISearchLocalStorage>({
    duration: "", // Duration of stay.
    date: [], // Dates.
    chosenLocations: [], // Chosen locations.
  });

  // Effect to retrieve data from local storage upon component mount.
  useEffect(() => {
    const storageSearch = localStorage.getItem("search");

    if (storageSearch) {
      const search: ISearchLocalStorage = JSON.parse(
        localStorage.getItem("search") ?? "",
      );

      setData(search);
    }
  }, []);

  // Render the SearchBar component.
  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.content)}>
        {/* Render the formatted search string */}
        <FormatSearchString
          date={data.date}
          duration={data.duration}
          chosenLocations={data.chosenLocations}
        />

        {/* Render the custom link for editing the search */}
        <CustomLink
          className={styles.searchEditLink}
          to={{ pathname: ROOT, search: "edit" }}>
          {t("edit search")}
        </CustomLink>
      </div>
    </div>
  );
}

// Export the SearchBar component for use in other parts of the application.
export default SearchBar;
