const currentDate = new Date();
const currentYear = currentDate.getFullYear();

/**
 * Generates a list of years.
 * @param {number} [lastYear=0] The number of years to add after the current year.
 * @param {number} [downYear=100] The number of years to subtract before the current year.
 * @returns {number[]} An array containing the list of years.
 */
function getYearsList(lastYear = 0, downYear = 100) {
  const yearsList = [];

  for (let i = currentYear - downYear; i <= currentYear + lastYear; i += 1) {
    yearsList.push(i);
  }

  return yearsList;
}

export default getYearsList;
