/**
 * Generates a list of days for a given year and month.
 * @param {string} yearStr - The string representation of the year.
 * @param {string} monthStr - The string representation of the month (case-insensitive).
 * @returns {string[]} An array containing the days of the specified month.
 */
function getDaysList(yearStr?: string, monthStr?: string): string[] {
  if (!yearStr && !monthStr) {
    // Return an array of 30 days if neither year nor month is provided
    return Array.from({ length: 30 }, (_, i) => String(i + 1).padStart(2, "0"));
  }

  const currentYear = new Date().getFullYear();
  const year = yearStr ? parseInt(yearStr, 10) : currentYear;
  const month = monthStr ? parseInt(monthStr, 10) - 1 : -1; // Convert month to zero-based index, -1 if not provided

  if (
    Number.isNaN(year) ||
    (month !== -1 && (Number.isNaN(month) || month < 0 || month > 11))
  ) {
    throw new Error(
      `Invalid year or month: year=${yearStr}, month=${monthStr}`,
    );
  }

  if (month === -1) {
    // If month is not provided, return an array of 30 days
    return Array.from({ length: 30 }, (_, i) => String(i + 1).padStart(2, "0"));
  }

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const daysList: string[] = [];

  for (let i = 1; i <= daysInMonth; i += 1) {
    daysList.push(String(i).padStart(2, "0"));
  }

  return daysList;
}

export default getDaysList;
