import { Middleware } from "redux"; // Import Middleware type from Redux

import { store } from "../store"; // Import store instance
import { initApiToken, initPosSession } from "../actions/sessionActions"; // Import asynchronous action creators

// Function to handle invalid session by refreshing API tokens and POS session
const handleInvalidSession = async () => {
  try {
    // Dispatch actions to initialize API token and POS session
    await store.dispatch(initApiToken());
    await store.dispatch(initPosSession());
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);

    throw new Error(errorMessage);
  }
};

// Middleware to handle session-related actions
const sessionMiddleware: Middleware = () => (next) => async (action) => {
  // Check if the action has a requestId in its meta
  if ("meta" in action && "requestId" in action.meta) {
    // Call the next middleware in the chain and capture the result
    let result = await next(action);

    // Check if the result is an error and the status code is 401 (Unauthorized)
    if (result.error && result.payload && result.payload.status === 401) {
      // Handle invalid session by refreshing API tokens and POS session
      await handleInvalidSession();

      // Call the next middleware in the chain again
      result = await next(action);
    }

    // Return the result to the caller
    return result;
  }

  // If the action does not have a requestId, call the next middleware in the chain
  return next(action);
};

// Export the sessionMiddleware
export default sessionMiddleware;
