import { useEffect, useState } from "react";

const cachedScripts: string[] = [];

/**
 * Custom hook for dynamically loading scripts into a React component.
 * @param {string} url - The URL of the script to load.
 * @param {boolean} async - Optional. Specifies whether the script should be loaded asynchronously. Default is true.
 * @param {"head" | "body"} appendToHeadOrBody - Optional. Specifies whether the script should be appended to the head or body of the document. Default is "head".
 * @returns An array containing the loaded state and error state of the script: [loaded, error].
 */
function useScript(
  url: string,
  async = true,
  appendToHeadOrBody: "head" | "body" = "head",
) {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    // Check if the script has been cached
    if (cachedScripts.includes(url)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      // If not cached, add the script to the list of cached scripts
      cachedScripts.push(url);

      // Create a new script element
      const script = document.createElement("script");

      // Set script properties
      script.src = url;
      script.async = async;

      // Define event handlers for script load and error
      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = () => {
        // Remove the script from the list of cached scripts and from the document
        const index = cachedScripts.indexOf(url);

        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      // Add event listeners for script load and error
      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      // Append the script to the head or body of the document based on the appendToHeadOrBody parameter
      if (appendToHeadOrBody === "head") {
        document.head.appendChild(script);
      } else {
        document.body.appendChild(script);
      }

      // Clean up: remove event listeners when the component unmounts
      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      };
    }
    // Return undefined to satisfy ESLint rule requirements
    return undefined;
  }, [url, async, appendToHeadOrBody]);

  // Return an array containing the loaded state and error state of the script
  return [state.loaded, state.error];
}

// Export the useScript component
export default useScript;
