import { useEffect, useMemo, useState } from "react"; // React hooks for managing component state and lifecycle
import { useTranslation } from "react-i18next"; // Translation hook for multilingual support

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"; // Importing hooks for navigation and URL parameters from React Router.

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Import hooks for Redux store
import useScript from "../../../hooks/useScript"; // Custom hook for dynamically loading scripts
import MODAL from "../../../utils/constants/modal"; // Constants for modal types
import TOAST from "../../../utils/constants/toast"; // Constants for toast messages
import { showModal } from "../../../store/slices/modalSlice"; // Redux action for showing modals
import { useLazyCreateOpaquePaymentQuery } from "../../../store/services/PaymentService"; // Custom hook for making lazy queries to the payment service
import { showToast } from "../../../store/slices/toastSlice"; // Redux action for showing toast messages
import getMonthsList from "../../../utils/helpers/getMonthsList"; // Utility function to get a list of months
import getYearsList from "../../../utils/helpers/getYearsList"; // Utility function to get a list of years

// Validation functions for credit card payments
import {
  validateCVV,
  validateCreditCard,
  validateDate,
} from "../../../utils/validation/functions/payment";

import LoadingContainer from "../../containers/LoadingContainer"; // Container component for displaying loading state
import Input from "../../shared/Input"; // Reusable input component
import Button from "../../shared/Button"; // Reusable button component
import PriceType from "./molecules/PriceType"; // Component for selecting price type (full/deposit)
import CustomSelect from "../../shared/CustomSelect"; // Reusable custom select component
import LegalData from "./molecules/LegalData"; // Component for accepting legal terms
import BookingDetails from "../../base/BookingDetails"; // Component for displaying booking details

import styles from "./index.module.scss"; // Styles for the Payment component

// Definition of the AcceptObject interface.
interface AcceptObject {
  dispatchData?: (secureData: any, responseHandler: any) => void;
}

// React functional component representing the Payment page.
function Payment() {
  // React hooks for state management and side effects
  const navigate = useNavigate(); // Navigate function provided by React Router
  const dispatch = useTypedDispatch(); // Typed dispatch function from the Redux store
  const { t } = useTranslation(); // Translation function provided by react-i18next
  const [createOpaqueData] = useLazyCreateOpaquePaymentQuery(); // Lazy query hook for creating opaque payment data

  // React Router hooks for accessing location and URL parameters
  const { search } = useLocation(); // Location object for the current URL
  const { cruiseId } = useParams(); // Parameters extracted from the URL path
  const [params] = useSearchParams(); // URL search parameters extracted from the query string

  // Redux selectors for accessing state variables
  const { isCruiseLoading } = useTypedSelector((state) => state.search); // Flag indicating if cruise data is loading
  const { rooms } = useTypedSelector((state) => state.rooms); // Available rooms fetched from the Redux store
  const { sessionKey } = useTypedSelector((state) => state.session); // Session key for authentication
  const { guests } = useTypedSelector((state) => state.guests); // Guest information fetched from the Redux store

  // Environment variables fetched from the Redux store
  const {
    authorize_api_login_id,
    authorize_client_key,
    authorize_is_test,
    authorize_is_mine,
    terms_and_conditions,
    participant_release_agreement,
    supplemental_terms,
    show_cancellation_policy,
    guest_ticket_contract,
    bin_restriction,
  } = useTypedSelector((state) => state.environment);

  // State variables for managing payment details
  const [priceType, setPriceType] = useState<"full" | "deposit">("full"); // Selected price type (full or deposit)
  const [isLoading, setIsLoading] = useState(false); // Flag indicating if the payment is in progress

  // State variables for managing credit card details
  const [date, setDate] = useState({
    month: { value: "", errorMessage: "" }, // Selected expiration month with error message
    year: { value: "", errorMessage: "" }, // Selected expiration year with error message
  });

  const [card, setCard] = useState({
    cardNumber: { value: "", errorMessage: "" }, // Entered card number with error message
    expirationDate: {
      value: "",
      errorMessage: "",
    }, // Entered expiration date with error message
    cvv: { value: "", errorMessage: "" }, // Entered CVV code with error message
  });

  //  State variable to manage legal data related to terms and conditions, participant release agreement, supplemental terms, guest ticket contract, and show cancellation policy.
  const [legalData, setLegalData] = useState({
    terms_and_conditions: !terms_and_conditions,
    participant_release_agreement: !participant_release_agreement,
    supplemental_terms: !supplemental_terms,
    guest_ticket_contract: !guest_ticket_contract,
    show_cancellation_policy: !show_cancellation_policy,
  });

  // Memoized boolean indicating whether to use the Accept.js core library, based on the value of the authorize_is_mine environment variable.
  const isUseAcceptCore = useMemo(
    () => [true, "true"].includes(authorize_is_mine),
    [authorize_is_mine],
  );

  // Memoized script URL for loading the Accept.js library, based on the value of the authorize_is_test environment variable.
  const scriptUrl = useMemo(
    () =>
      [true, "true"].includes(authorize_is_test)
        ? "https://jstest.authorize.net/v1/Accept.js"
        : "https://js.authorize.net/v1/Accept.js",
    [authorize_is_test],
  );

  // Hook to load the Accept.js script asynchronously. The loading state is ignored, as only the script URL is needed.
  const [loading] = useScript(scriptUrl);

  const activeRestriction = useMemo(() => {
    const parsedRestrictions: Array<{
      number: string[];
      errorMessage: string;
    }> = JSON.parse(bin_restriction || "[]");

    const matchedRestriction = parsedRestrictions.find((bin) =>
      bin.number.some((number) => card.cardNumber.value.startsWith(number)),
    );

    return matchedRestriction;
  }, [card.cardNumber.value]);

  //  Memoized boolean indicating whether the payment form is valid, based on the validity of card details and legal data.
  const isFormValid = useMemo(() => {
    // Check if card number, expiration date, and CVV are valid
    const isNumberValid =
      !!card.cardNumber.value && !card.cardNumber.errorMessage;

    const isExpDateValid =
      !!card.expirationDate.value && !card.expirationDate.errorMessage;

    const isCvvValid = !!card.cvv.value && !card.cvv.errorMessage;

    // Check if all legal data fields are true
    const isLegalDataValid = Object.values(legalData).every((el) => !!el);

    // Return true if all conditions are met
    return isNumberValid && isExpDateValid && isCvvValid && isLegalDataValid;
  }, [card, legalData]);

  // Memoized transactions array containing payment details, based on room pricing and selected price type.
  const transactions: [
    {
      full?: number;
      deposit?: number;
      amount: number;
      currency: string;
      made_by: string;
      transaction_type: string;
    },
  ] = useMemo(() => {
    const output = {
      full: 0,
      deposit: 0,
      amount: 0,
      currency: rooms?.[1].pricing?.payment_schedule?.[0].currency ?? "",
      made_by: "Website Authorize.net",
      transaction_type: "creditcard",
    };

    const deposit = Object.values(rooms ?? {}).reduce((sum, room) => {
      const roomDeposit = room?.pricing?.payment_schedule?.[0]?.amount ?? 0;

      return Number(sum) + Number(roomDeposit);
    }, 0);

    output.deposit = deposit;

    Object.values(rooms ?? {}).forEach((room) => {
      const paymentSchedule = room?.pricing?.payment_schedule;

      if (Array.isArray(paymentSchedule)) {
        paymentSchedule.forEach((payment) => {
          if (payment.amount) {
            output.full += parseFloat(payment.amount);
          }
        });
      }
    });

    if (priceType === "full") {
      output.amount = output.full;
    }

    if (priceType === "deposit") {
      output.amount = output.deposit;
    }

    output.amount = Math.ceil(output.amount * 100) / 100;
    output.full = Math.ceil(output.full * 100) / 100;
    output.deposit = Math.ceil(output.deposit * 100) / 100;

    return [output];
  }, [rooms, card, priceType]);

  // Memoized month list obtained from helper function, with each item having a value and label property
  const monthList = useMemo(() => {
    return getMonthsList().map((item) => ({ value: item, label: item }));
  }, []);

  // Memoized year list obtained from helper function, sorted in descending order, with each item having a value and label property
  const yearList = useMemo(() => {
    return getYearsList(20, 0)
      .sort((a, b) => b - a)
      .map((item) => ({
        value: String(item),
        label: String(item),
      }));
  }, []);

  // Function to dispatch a modal action to show the itinerary modal
  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  // Handler function to update the month value in the expiration date state
  const handleExpirationDateMonth = ({ value }: { value: string }) => {
    setDate((prev) => ({ ...prev, month: { value, errorMessage: "" } }));
  };

  // Handler function to update the year value in the expiration date state
  const handleExpirationDateYear = ({ value }: { value: string }) => {
    setDate((prev) => ({ ...prev, year: { value, errorMessage: "" } }));
  };

  // Handler function to update the value of a card input field
  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey: string;
  }) => {
    setCard((prev) => ({ ...prev, [valueKey]: { errorMessage: "", value } }));
  };

  // Handler function to update the selected price type (full or deposit)
  const handlePriceTypeChange = (type: typeof priceType) => setPriceType(type);

  // Handler function to update the value of a legal data field
  const handleLegalDataChange = ({
    value,
    valueKey,
  }: {
    value: boolean;
    valueKey: string;
  }) => {
    setLegalData((prev) => ({ ...prev, [valueKey]: value }));
  };

  // Function to set error messages for card input fields
  const setErrors = ({
    cardNumber,
    cvv,
    expirationDate,
    combinedLength,
  }: {
    cardNumber: boolean | string;
    cvv: boolean | string;
    expirationDate: boolean | string;
    combinedLength: boolean;
  }) => {
    setCard((prev) => {
      const output = structuredClone(prev);

      if (typeof cardNumber === "string") {
        output.cardNumber.errorMessage = cardNumber;
      } else if (!cardNumber) {
        output.cardNumber.errorMessage = t("Card number is not valid");
      }

      if (typeof cvv === "string") {
        output.cvv.errorMessage = cvv;
      } else if (!cvv) {
        output.cvv.errorMessage = t("CVV number is not valid");
      }

      if (typeof expirationDate === "string") {
        output.expirationDate.errorMessage = expirationDate;
      } else if (!expirationDate) {
        output.expirationDate.errorMessage = t("Date is not valid");
      }

      if (!combinedLength) {
        output.cvv.errorMessage = t(
          "Combined length of card number and CVV should be 19",
        );
      }

      return output;
    });
  };

  // Handler function for processing accepted payment data
  const acceptDataHandler = async (opaqueData: {
    opaqueData: { dataValue: string };
  }) => {
    try {
      // Create a clone of the transactions object
      const clonedTransictions = structuredClone(transactions[0]);

      // If the full and deposit properties exist in the cloned transactions, delete them
      if ("full" in clonedTransictions && "deposit" in clonedTransictions) {
        delete clonedTransictions.full;
        delete clonedTransictions.deposit;
      }

      // Make a request to create opaque payment data with the provided information
      const response = await createOpaqueData({
        session: sessionKey,
        pnr: rooms?.[1]?.pnr ?? "",
        source: params.get("source"),
        transactions: [
          {
            ...clonedTransictions,
            amount: `${clonedTransictions.amount}`,
            tokenized: opaqueData.opaqueData.dataValue,
          },
        ],
      });

      // Handle response based on whether there are errors
      if (response.data.errors && response.isError) {
        // Dispatch an error toast message
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Payment failed"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      } else {
        // Dispatch a success toast message and navigate to payment confirmation page
        dispatch(
          showToast({
            type: TOAST.SUCCESS_TYPE,
            message: t("Payment success"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );

        navigate(`/search-results/${cruiseId}/payment-confirmation${search}`);
      }
    } catch (error) {
      // Handle errors occurred during the process
      const errorMessage =
        error instanceof Error ? error.message : String(error);

      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Payment failed"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      throw new Error(errorMessage);
    } finally {
      // Set loading state to false after the operation is completed
      setIsLoading(false);
    }
  };

  // Function to make a request to create opaque payment data
  const makeOpaqueRequet = async () => {
    try {
      // Extract card data from the state
      const cardData = {
        cardNumber: card.cardNumber.value,
        year: card.expirationDate.value.split("-")[0],
        month: card.expirationDate.value.split("-")[1],
        cardCode: card.cvv.value,
      };

      // Construct opaque transaction request object
      const opaqueTransactionRequest = [
        {
          currency: transactions[0].currency ?? "",
          amount: transactions[0].amount,
          transaction_type: "creditcard",
          made_by: "Website",
          card: {
            pan: cardData.cardNumber,
            expiry_month: cardData.month,
            expiry_year: cardData.year,
            cvv: cardData.cardCode,
            name: `${guests?.[1]?.[1].given_name ?? ""} ${guests?.[1]?.[1].last_name ?? ""}`,
            address: {
              address: `${guests?.[1]?.[1].address1} ${guests?.[1]?.[1].address2 ?? ""}`,
              city: guests?.[1]?.[1].city,
              state: guests?.[1]?.[1].state,
              zip: guests?.[1]?.[1].zip_code,
              country: guests?.[1]?.[1].country,
            },
            email: guests?.[1]?.[1].email,
            phone: guests?.[1]?.[1].phone,
          },
        },
      ];

      // Make a request to create opaque payment data with the constructed transaction request
      const response = await createOpaqueData({
        session: sessionKey,
        pnr: rooms?.[1]?.pnr ?? "",
        source: params.get("source"),
        transactions: opaqueTransactionRequest,
      });

      // Handle response based on whether there are errors
      if (response.data.errors && response.isError) {
        // Dispatch an error toast message
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Payment failed"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      } else {
        // Dispatch a success toast message and navigate to payment confirmation page
        dispatch(
          showToast({
            type: TOAST.SUCCESS_TYPE,
            message: t("Payment success"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );

        navigate(`/search-results/${cruiseId}/payment-confirmation${search}`);
      }
    } catch (error) {
      // Handle errors occurred during the process
      const errorMessage =
        error instanceof Error ? error.message : String(error);

      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Payment failed"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      throw new Error(errorMessage);
    } finally {
      // Set loading state to false after the operation is completed
      setIsLoading(false);
    }
  };

  // Handler function for initiating payment process
  const payHandler = async () => {
    // Prepare authentication data for payment
    const authData = {
      apiLoginID: authorize_api_login_id,
      clientKey: authorize_client_key,
    };

    // Extract card data from the state
    const cardData = {
      cardNumber: card.cardNumber.value,
      year: card.expirationDate.value.split("-")[0],
      month: card.expirationDate.value.split("-")[1],
      cardCode: card.cvv.value,
    };

    // Construct transaction request object for accepting payment
    const acceptTransactionRequest = {
      amount: transactions[0].amount,
      transactionType: "authCaptureTransaction",
      description: "Cabin booking payment",
      billTo: {
        firstName: guests?.[1]?.[1].given_name ?? "",
        lastName: guests?.[1]?.[1].last_name ?? "",
        city: guests?.[1]?.[1].city ?? "",
        state: guests?.[1]?.[1].state ?? "",
        zip: guests?.[1]?.[1].zip_code ?? "",
        country: guests?.[1]?.[1].country ?? "",
      },
      refId: rooms?.[1]?.pnr ?? "",
    };

    // Assemble secure data object for payment processing
    const secureData = {
      authData,
      cardData,
      transactionRequest: acceptTransactionRequest,
    };

    // Set loading state to true before initiating payment
    setIsLoading(true);

    // Check if the Accept.js library is available and should be used
    if ("Accept" in window && isUseAcceptCore) {
      // If Accept.js is available, use dispatchData method to initiate payment
      const accept: AcceptObject = window.Accept as AcceptObject;

      if (accept.dispatchData && typeof accept.dispatchData === "function") {
        accept.dispatchData(secureData, acceptDataHandler);
      }
    } else {
      // If Accept.js is not available, make opaque request for payment
      makeOpaqueRequet();
    }
  };

  // Function to validate form data before initiating payment
  const validateForm = () => {
    // Validate card number, CVV, and expiration date
    const cardNumber = validateCreditCard(card.cardNumber.value);
    const cvv = validateCVV(card.cvv.value);
    const expirationDate = validateDate(card.expirationDate.value);

    // Validate combined length of card number and CVV
    const combinedLength =
      card.cardNumber.value.replace(/\s/g, "").length +
        card.cvv.value.length ===
      19;

    // If any validation fails, set error messages; otherwise, initiate payment
    if (!cardNumber || !cvv || !expirationDate || !combinedLength) {
      setErrors({
        cardNumber,
        cvv,
        expirationDate,
        combinedLength,
      });
    } else {
      payHandler();
    }
  };

  // Effect to update expiration date format when month or year changes
  useEffect(() => {
    setCard((prevState) => ({
      ...prevState,
      expirationDate: {
        ...prevState.expirationDate,
        value:
          Boolean(date.year.value) && Boolean(date.month.value)
            ? `${date.year.value}-${date.month.value}`
            : "",
      },
    }));
  }, [date.month.value, date.year.value]);

  useEffect(() => {
    if (activeRestriction) {
      setCard((prev) => ({
        ...prev,
        cardNumber: {
          ...prev.cardNumber,
          errorMessage: activeRestriction.errorMessage,
        },
      }));
    } else {
      setCard((prev) => ({
        ...prev,
        cardNumber: {
          ...prev.cardNumber,
          errorMessage: "",
        },
      }));
    }
  }, [activeRestriction]);

  // Effect to scroll to the top of the page when component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Return JSX for the Payment component
  return (
    <LoadingContainer isLoading={!loading && !isCruiseLoading}>
      {/* Container for the payment content */}
      <div className={styles.container}>
        {/* Content section */}
        <div className={styles.content}>
          {/* Left section */}
          <div className={styles.left}>
            {/* Title */}
            <h1 className={styles.title}>{t("Payment")}</h1>

            {/* Price type selection */}
            <PriceType
              full={transactions[0].full ?? 0}
              deposit={transactions[0].deposit ?? 0}
              currency={transactions[0].currency ?? ""}
              onPriceTypeChange={handlePriceTypeChange}
            />

            {/* Payment form */}
            <form className={styles.form} onSubmit={validateForm}>
              {/* Card number input */}
              <div className={styles.inputs}>
                <Input
                  className={styles.input}
                  value={card.cardNumber.value}
                  valueKey="cardNumber"
                  label={t("Card number")}
                  placeholder={t("Enter 16 digits")}
                  errorMessage={card.cardNumber.errorMessage}
                  onChange={handleInputChange}
                />
              </div>

              {/* Expiration date inputs */}
              <div className={styles.inputsDate}>
                <CustomSelect
                  className={styles.input}
                  value={date.month.value}
                  label={t("month")}
                  errorMessage={card.expirationDate.errorMessage}
                  items={monthList}
                  placeholder={t("Month")}
                  onChange={handleExpirationDateMonth}
                />

                <CustomSelect
                  className={styles.input}
                  value={date.year.value}
                  label={t("year")}
                  errorMessage={card.expirationDate.errorMessage}
                  items={yearList.reverse()}
                  placeholder={t("Year")}
                  onChange={handleExpirationDateYear}
                />
              </div>

              {/* CVV input */}
              <div className={styles.inputs}>
                <Input
                  className={styles.input}
                  value={card.cvv.value}
                  valueKey="cvv"
                  label={t("Card Code")}
                  placeholder={t("3 digits")}
                  errorMessage={card.cvv.errorMessage}
                  onChange={handleInputChange}
                />
              </div>

              {/* Legal data checkboxes */}
              <LegalData
                terms_and_conditions_checked={legalData.terms_and_conditions}
                participant_release_agreement_checked={
                  legalData.participant_release_agreement
                }
                supplemental_terms_checked={legalData.supplemental_terms}
                guest_ticket_contract_checked={legalData.guest_ticket_contract}
                show_cancellation_policy_checked={
                  legalData.show_cancellation_policy
                }
                onChange={handleLegalDataChange}
              />
            </form>

            {/* Pay button */}
            <div className={styles.inputs}>
              <Button
                onClick={validateForm}
                className={styles.button}
                label={t("Pay")}
                loading={isLoading}
                disabled={!isFormValid || isLoading}
              />
            </div>
          </div>

          {/* Right section */}
          <div className={styles.right}>
            {/* View itinerary button */}
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowItineraryModal}
            />

            {/* Booking details */}
            <BookingDetails />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Payment;
