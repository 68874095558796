// Import necessary modules and constants
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DEFAULT_GUEST_FIELDS } from "../../utils/constants/defaultGuestFields";

// Define the structure of a single guest's fields
export interface GuestFields {
  address1: string;
  address2: string;
  city: string;
  country: string;
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
  email: string;
  given_name: string;
  last_name: string;
  gender: string;
  lead_passenger: boolean;
  nationality: string;
  phone: string;
  state: string;
  title: string;
  zip_code: string;
  passport_number: string;
  issue_date: string;
  expiry_date: string;
  place_of_birth: string;
  country_of_issue: string;
  request: string;
  isExpanded?: boolean; // Optional property indicating if the guest details are expanded
}

// Define the structure of multiple guests' fields
export type GuestsFields = Record<number, Record<number, GuestFields>>;

// Define the structure of the guests state
export interface GuestsState {
  guests: GuestsFields;
}

// Define the initial state for the guests slice
const initialState: GuestsState = {
  guests: {
    1: {
      1: DEFAULT_GUEST_FIELDS, // Set default guest fields for the first guest in the first stateroom
    },
  },
};

// Import necessary modules and constants
const guestsSlice = createSlice({
  name: "guests",
  initialState,
  reducers: {
    clearGuest(state: GuestsState) {
      // Reset guests state to default
      state.guests = {
        1: {
          1: DEFAULT_GUEST_FIELDS,
        },
      };
    },
    updateGuestsState(state: GuestsState, action: PayloadAction<GuestsFields>) {
      // Update guests state with new guest fields
      state.guests = action.payload;
    },
    updateGuest(
      state: GuestsState,
      action: PayloadAction<{
        stateroomNumber: number;
        guestNumber: number;
        guestFields: GuestFields;
      }>,
    ) {
      // Update guest fields for a specific guest in a specific stateroom
      const { stateroomNumber, guestNumber, guestFields } = action.payload;
      const isRoomExists = !!state.guests[stateroomNumber];

      // Check if the stateroom exists in the state
      if (!isRoomExists) {
        state.guests[stateroomNumber] = {
          [guestNumber]: guestFields,
        };
      }

      // If the stateroom already exists, update the guest fields
      if (isRoomExists) {
        state.guests[stateroomNumber][guestNumber] = guestFields;
      }
    },
  },
});

// Export the action creators generated by createSlice
export const { clearGuest, updateGuestsState, updateGuest } =
  guestsSlice.actions;

// Export the reducer function generated by createSlice
export default guestsSlice.reducer;
