import classNames from "classnames";
import { useState, useRef, useMemo, useEffect } from "react";

import useOnClickOutside from "../../../hooks/useClickOutside";

import styles from "./index.module.scss";

interface ISelectProps {
  className?: string;
  value: string;
  valueKey?: string;
  items: Array<{ value: string; label: string }>;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  isRequired?: boolean;
  onChange: ({ value, valueKey }: { value: string; valueKey: string }) => void;
}

function CustomSelect({
  className,
  value,
  valueKey,
  items,
  label,
  placeholder,
  errorMessage,
  isRequired,
  onChange,
}: ISelectProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [internalValue, setInternalValue] = useState(value ?? "");

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const activeOption = useMemo(
    () => items.find((el) => el.value === value),
    [value],
  );
  const outsideRef = useRef(null);

  const handleChange = (newValue: string) => () => {
    setIsVisible(false);
    setInternalValue(newValue);

    if (onChange) {
      onChange({ value: newValue, valueKey: valueKey ?? "" });
    }
  };

  useOnClickOutside(outsideRef, () => setIsVisible(false));

  return (
    <div className={styles.container}>
      {label && (
        <span className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </span>
      )}

      <div ref={outsideRef} className={styles.fieldContainer}>
        <div
          className={classNames(styles.field, className, {
            [styles.field_error]: !!errorMessage,
          })}
          onClick={() => setIsVisible((prev) => !prev)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setIsVisible((prev) => !prev);
            }
          }}
          role="button"
          tabIndex={0}
          aria-haspopup="listbox"
          aria-expanded={isVisible}>
          <span
            className={classNames(styles.value, {
              [styles.placeholder]: !value,
              [styles.value_visible]: isVisible,
            })}>
            {activeOption?.label ?? placeholder}
          </span>
        </div>

        <div
          className={classNames(styles.items, {
            [styles.items_visible]: isVisible,
          })}
          role="listbox">
          {items.map((item) => {
            const isChosen = internalValue === item.value;

            return (
              <div
                key={item.value}
                className={classNames(styles.item, {
                  [styles.item_chosen]: isChosen,
                })}
                onClick={handleChange(item.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleChange(item.value)();
                  }
                }}
                role="option"
                tabIndex={0}
                aria-selected={isChosen}>
                <span>{item.label}</span>
              </div>
            );
          })}
        </div>
      </div>

      <span className={styles.error}>{errorMessage}</span>
    </div>
  );
}

CustomSelect.defaultProps = {
  className: "",
  label: "",
  valueKey: "",
  placeholder: "",
  errorMessage: "",
  isRequired: false,
};

export default CustomSelect;
