import { useEffect, useState } from "react"; // Importing useEffect and useState hooks from React
import { useLocation, useNavigate } from "react-router-dom"; // Importing useLocation and useNavigate hooks from React Router
import { useTranslation } from "react-i18next"; // Importing useTranslation hook from react-i18next
import dayjs from "dayjs"; // Importing dayjs library for date manipulation

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Importing various utilities and selectors from Redux store
import { countryCodeToFullName } from "../../../utils/helpers/country"; // Importing helper function for country codes
import { SearchAPI } from "../../../store/services/SearchService"; // Importing SearchAPI service from Redux store
import { ICruise } from "../../../store/slices/searchSlice"; // Importing ICruise interface from Redux store
import { currencyToFormat } from "../../../utils/helpers/currency"; // Importing helper function for formatting currency

// Importing selectors for search results from Redux store
import {
  getPriceByCruise,
  selectSearch,
  selectSearchResultsByFilter,
} from "../../../store/selectors";

import LoadingContainer from "../../containers/LoadingContainer"; // Importing LoadingContainer component
import SearchFilter from "../../base/SearchFilter"; // Importing SearchFilter component
import Button from "../../shared/Button"; // Importing Button component
import PaginationComponent from "../../shared/Pagination"; // Importing Pagination component

import styles from "./index.module.scss"; // Importing styles for SearchResults component

// Declaring SearchResults functional component
function SearchResults() {
  const dispatch = useTypedDispatch(); // Initializing useDispatch hook for typed dispatch
  const navigate = useNavigate(); // Initializing useNavigate hook for programmatic navigation
  const { t } = useTranslation(); // Initializing useTranslation hook for translation

  const { pathname, search } = useLocation(); // Getting current pathname and search query from location

  const { results, isLoading } = useTypedSelector(selectSearchResultsByFilter); // Getting search results and loading state from Redux store
  const { results: searchResults } = useTypedSelector(selectSearch); // Getting search results from Redux store
  const { date_format, show_pagination } = useTypedSelector(
    (state) => state.environment,
  ); // Getting date format from Redux store

  // Getting search item image source from Redux store
  const { search_item_image_source } = useTypedSelector(
    (state) => state.environment,
  );

  // Cruise items to render
  const [items, setItems] = useState<ICruise[]>([]);

  // Function to initialize search results
  const initSearchResults = () => {
    // Dispatching action to initialize search results
    dispatch(SearchAPI.endpoints.initSearchResults.initiate({ search }));
  };

  // Function to get price from cruise object
  const getPriceFrom = (cruise: ICruise) => {
    // Getting price by cruise from Redux store
    const price = getPriceByCruise(cruise);

    // Formatting currency
    return currencyToFormat(
      price,
      cruise.markets.currency ?? "USD", // Getting currency from Redux store
    );
  };

  // Function to handle navigation to cruise details
  const handleNavigate = (cruise: ICruise) => () => {
    // Checking if last character of pathname is '/'
    const pathName =
      pathname[pathname.length - 1] === "/" ? pathname.slice(0, -1) : pathname;

    // Navigating to cruise details page
    navigate(`${pathName}/${cruise.code}${search}&source=${cruise.source}`);
  };

  // Running initSearchResults function on component mount
  useEffect(initSearchResults, []);

  // Returning JSX
  return (
    // Container for SearchResults component
    <div className={styles.container}>
      {/* Title for search results */}
      <h1 className={styles.title}>{t("search results")}</h1>

      {/* Loading container */}
      <LoadingContainer isLoading={isLoading}>
        {/* Rendering search filter if there are multiple results */}
        {searchResults && searchResults?.length > 1 && <SearchFilter />}
        {/* Container for search results */}
        <div className={styles.results}>
          {/* Checking if there are no results */}
          {(show_pagination && !items?.length) ||
          (!items?.filter((item) => getPriceFrom(item)).length &&
            !show_pagination &&
            !results?.length) ||
          !results?.filter((item) => getPriceFrom(item)).length ? (
            <p>{t("no results found")}</p>
          ) : (
            // Mapping through search results
            (show_pagination ? items : results)
              ?.filter((item) => getPriceFrom(item))
              .map((cruise) => {
                const priceFrom = getPriceFrom(cruise); // Getting price from cruise

                // Returning JSX for each search result
                return (
                  // Container for each search result item
                  <div key={cruise.code} className={styles.item}>
                    {/* Image for search result */}
                    <img
                      alt={cruise?.cruise?.name}
                      src={cruise[search_item_image_source]?.images?.[0]}
                      className={styles.image}
                    />

                    {/* Bottom section of search result item */}
                    <div className={styles.bottom}>
                      {/* Body section of search result item */}
                      <div className={styles.body}>
                        {/* Name of cruise */}
                        <p className={styles.name}>{cruise?.cruise?.name}</p>

                        {/* Details table */}
                        <table className={styles.details}>
                          <tbody>
                            {/* Row for ship name */}
                            <tr>
                              <td>{t("SHIP NAME")}</td>
                              <td>{cruise?.ship?.name}</td>
                            </tr>

                            {/* Row for region */}
                            <tr>
                              <td>{t("REGION")}</td>
                              <td>
                                {cruise?.cruise?.countries
                                  ?.map(countryCodeToFullName)
                                  .join(", ")}
                              </td>
                            </tr>

                            {/* Row for departure date */}
                            <tr>
                              <td>{t("DEPARTS")}</td>
                              <td>
                                {dayjs(cruise?.embark).format(date_format)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* Footer section of search result item */}
                      <div className={styles.footer}>
                        {/* Price section */}
                        <div className={styles.price}>
                          <span className={styles.price_from}>{t("From")}</span>

                          <span className={styles.price_value}>
                            {/* Price value with price for person */}
                            {priceFrom}&nbsp;pp
                          </span>
                        </div>

                        {/* Button to navigate to cruise details */}
                        <Button
                          label={t("next")}
                          onClick={handleNavigate(cruise)}
                          className={styles.button}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
          )}
        </div>

        {show_pagination && (
          <PaginationComponent data={results} setData={setItems} />
        )}
      </LoadingContainer>
    </div>
  );
}

// Exporting SearchResults component
export default SearchResults;
