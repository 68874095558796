/* eslint-disable @typescript-eslint/no-shadow */
import { createAsyncThunk } from "@reduxjs/toolkit"; // Import createAsyncThunk function from Redux Toolkit

// Import necessary APIs from services
import { SessionApi } from "../services/SessionService";
import { EnvironmentAPI } from "../services/EnvironmentService";
import { PosAPI } from "../services/PosService";

import { RootState } from "../store"; // Import RootState type from store
import { showToast } from "../slices/toastSlice"; // Import showToast action creator from toastSlice
import TOAST from "../../utils/constants/toast"; // Import constants for toast messages

// Get environment values from local env file
const {
  REACT_APP_PRIMARY_COLOR: primary_color,
  REACT_APP_SECONDARY_COLOR: secondary_color,
  REACT_APP_TEXT_PRIMARY_COLOR: text_primary_color,
  REACT_APP_TEXT_SECONDARY_COLOR: text_secondary_color,
  REACT_APP_INPUT_BORDER_COLOR: input_border_color,
  REACT_APP_INPUT_BACKGROUND_COLOR: input_background_color,
  REACT_APP_INPUT_PLACEHOLDER_COLOR: input_placeholder_color,
  REACT_APP_INPUT_TEXT_COLOR: input_text_color,
  REACT_APP_INPUT_FOCUS_BORDER_COLOR: input_focus_border_color,
  REACT_APP_ERROR_COLOR: error_color,
  REACT_APP_MENU_BACKGROUND_COLOR: menu_background_color,
  REACT_APP_MENU_TEXT_COLOR: menu_text_color,
  REACT_APP_ELEMENT_BACKGROUND_PRIMARY: element_background_primary,
  REACT_APP_ELEMENT_BACKGROUND_SECONDARY: element_background_secondary,
  REACT_APP_ELEMENT_BACKGROUND_DARK: element_background_dark,
  REACT_APP_BACKGROUND_COLOR: background_color,
  REACT_APP_LINK_COLOR: link_color,
  REACT_APP_SECTION_BACKGROUND_COLOR: section_background_color,
  REACT_APP_SHADOW_COLOR: shadow_color,
  REACT_APP_SUBTEXT_COLOR: subtext_color,
  REACT_APP_DELIMITTER_LINE_COLOR: delimitter_line_color,
  REACT_APP_SVG_ICONS_PRIMARY: svg_icons_primary,
  REACT_APP_SVG_ICONS_SECONDRY: svg_icons_secondary,
  REACT_APP_SEARCH_BUTTON_BACKGROUND: search_button_background,
  REACT_APP_CRUISE_PAGE: cruise_page,
  REACT_APP_SITE_LOGO: site_logo,
  REACT_APP_HEADER_BACKGROUND: header_background,
  REACT_APP_FOOTER_BACKGROUND: footer_background,
  REACT_APP_FOOTER_FONT_COLOR: footer_font_color,
  REACT_APP_CABIN_SELECT_API_USERNAME: api_username,
  REACT_APP_CABIN_SELECT_API_PASSWORD: api_password,
  REACT_APP_CABIN_SELECT_API_AGENCY: api_agency,
  REACT_APP_CABIN_SELECT_SEARCH_DOMAIN: search_domain,
  REACT_APP_CABIN_SELECT_TOKEN_DOMAIN: token_domain,
  REACT_APP_CABIN_SELECT_POS_DOMAIN: pos_domain,
  REACT_APP_CABIN_SELECT_API_DOMAIN: api_domain,
  REACT_APP_CABIN_SELECT_APP_LANGUAGE: app_language,
  REACT_APP_CABIN_SELECT_PAYMENT_DOMAIN: payment_domain,
  REACT_APP_CABIN_SELECT_DATE_FORMAT: date_format,
  REACT_APP_CABIN_SELECT_API_LANGUAGE: api_language,
  REACT_APP_CABIN_SELECT_AUTHORIZE_API_LOGIN_ID: authorize_api_login_id,
  REACT_APP_CABIN_SELECT_AUTHORIZE_CLIENT_KEY: authorize_client_key,
  REACT_APP_CABIN_SELECT_TABLE_VIEW: table_view,
  REACT_APP_CABIN_SELECT_API_AGENT: api_agent,
  REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE: authorize_is_mine,
  REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST: authorize_is_test,
  REACT_APP_PAYMENT_API_PATH: payment_api_path,
  REACT_APP_SEARCH_ITEM_IMAGE_SOURCE: search_item_image_source,
  REACT_APP_PRIVACY_POLICY: privacy_policy,
  REACT_APP_TERMS_AND_CONDITIONS: terms_and_conditions,
  REACT_APP_PARTICIPANT_RELEASE_AGREEMENT: participant_release_agreement,
  REACT_APP_SUPPLEMENTAL_TERMS: supplemental_terms,
  REACT_APP_GUEST_TICKET_CONTRACT: guest_ticket_contract,
  REACT_APP_SUBTRACT_GFT: subtract_gft,
  REACT_APP_STRIP_NO_STOP_ITINERARY_ITEMS: strip_no_stop_itinerary_items,
  REACT_APP_SHOW_CANCELLATION_POLICY: show_cancellation_policy,
  REACT_APP_BREADCRUMBS_TEXT: breadcrumbs_text,
  REACT_APP_SHOW_PRICING_BREAKDOWN: show_pricing_breakdown,
  REACT_APP_CHECK_EMAIL_UNIQUENESS: check_email_uniqueness,
  REACT_APP_SHOW_PAGINATION: show_pagination,
  REACT_APP_PAGINATION_TYPE: pagination_type,
  REACT_APP_PAGINATION_COUNT: pagination_count,
  REACT_APP_SKIP_PAYMENT_STEP: skip_payment_step,
  REACT_APP_HEADER_SHOW_CURRENCY_CODE: header_show_currency_code,
  REACT_APP_HEADER_SHOW_LANGUAGE_PICTURE: header_show_language_picture,
  REACT_APP_HEADER_BACKGROUND_COLOR: header_background_color,
  REACT_APP_HEADER_TEXT_COLOR: header_text_color,
  REACT_APP_HEADER_TELEPHONE_NUMBER: header_telephone_number,
  REACT_APP_HEADER_LOGO_PICTURE: header_logo_picture,
  REACT_APP_FOOTER_BACKGROUND_COLOR: footer_background_color,
  REACT_APP_FOOTER_TEXT_COLOR: footer_text_color,
  REACT_APP_FOOTER_EMAIL_ADDRESS: footer_email_address,
  REACT_APP_FOOTER_ADDRESS: footer_address,
  REACT_APP_GFT_ADDITIONAL_TEXT: gft_additional_text,
  REACT_APP_DESTINATION_INPUT_PLACEHOLDER: destination_input_placeholder,
  REACT_APP_BIN_RESTRICTION: bin_restriction,
  REACT_APP_FAVICON: favicon,
  REACT_APP_HEADER_LOGO_HEIGHT: header_logo_height,
} = process.env;

export const initEnvironment = createAsyncThunk(
  "session/initEnvironment",
  async (_, { dispatch }) => {
    // Try to get environment values from EnvironmentAPI
    const { data } = await dispatch(
      EnvironmentAPI.endpoints.initEnvironment.initiate(null),
    );

    // If yes return data object with environment values from EnvironmentAPI
    if (data) {
      const { acf } = data;

      const {
        REACT_APP_PRIMARY_COLOR: primary_color,
        REACT_APP_SECONDARY_COLOR: secondary_color,
        REACT_APP_TEXT_PRIMARY_COLOR: text_primary_color,
        REACT_APP_TEXT_SECONDARY_COLOR: text_secondary_color,
        REACT_APP_INPUT_BORDER_COLOR: input_border_color,
        REACT_APP_INPUT_BACKGROUND_COLOR: input_background_color,
        REACT_APP_INPUT_PLACEHOLDER_COLOR: input_placeholder_color,
        REACT_APP_INPUT_TEXT_COLOR: input_text_color,
        REACT_APP_INPUT_FOCUS_BORDER_COLOR: input_focus_border_color,
        REACT_APP_ERROR_COLOR: error_color,
        REACT_APP_MENU_BACKGROUND_COLOR: menu_background_color,
        REACT_APP_MENU_TEXT_COLOR: menu_text_color,
        REACT_APP_ELEMENT_BACKGROUND_PRIMARY: element_background_primary,
        REACT_APP_ELEMENT_BACKGROUND_SECONDARY: element_background_secondary,
        REACT_APP_ELEMENT_BACKGROUND_DARK: element_background_dark,
        REACT_APP_BACKGROUND_COLOR: background_color,
        REACT_APP_LINK_COLOR: link_color,
        REACT_APP_SECTION_BACKGROUND_COLOR: section_background_color,
        REACT_APP_SHADOW_COLOR: shadow_color,
        REACT_APP_SUBTEXT_COLOR: subtext_color,
        REACT_APP_DELIMITTER_LINE_COLOR: delimitter_line_color,
        REACT_APP_SVG_ICONS_PRIMARY: svg_icons_primary,
        REACT_APP_SVG_ICONS_SECONDRY: svg_icons_secondary,
        REACT_APP_SEARCH_BUTTON_BACKGROUND: search_button_background,
        cruise_page,
        site_logo,
        header_background,
        footer_background,
        footer_font_color,
        react_app_cabin_select_api_username: api_username,
        react_app_cabin_select_api_password: api_password,
        react_app_cabin_select_api_agency: api_agency,
        react_app_cabin_select_search_domain: search_domain,
        react_app_cabin_select_token_domain: token_domain,
        react_app_cabin_select_pos_domain: pos_domain,
        REACT_APP_CABIN_SELECT_API: api_domain,
        REACT_APP_CABIN_SELECT_LANGUAGE: app_language,
        react_app_cabin_select_payment_domain: payment_domain,
        react_app_cabin_select_date_format: date_format,
        react_app_cabin_select_api_language: api_language,
        react_app_cabin_select_authorize_api_login_id: authorize_api_login_id,
        react_app_cabin_select_authorize_client_key: authorize_client_key,
        react_app_cabin_select_table_view: table_view,
        REACT_APP_CABIN_SELECT_API_AGENT: api_agent,
        REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE: authorize_is_mine,
        REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST: authorize_is_test,
        REACT_APP_PAYMENT_API_PATH: payment_api_path,
        REACT_APP_SEARCH_ITEM_IMAGE_SOURCE: search_item_image_source,
        REACT_APP_PRIVACY_POLICY: privacy_policy,
        REACT_APP_TERMS_AND_CONDITIONS: terms_and_conditions,
        REACT_APP_PARTICIPANT_RELEASE_AGREEMENT: participant_release_agreement,
        REACT_APP_SUPPLEMENTAL_TERMS: supplemental_terms,
        REACT_APP_GUEST_TICKET_CONTRACT: guest_ticket_contract,
        REACT_APP_SUBTRACT_GFT: subtract_gft,
        REACT_APP_STRIP_NO_STOP_ITINERARY_ITEMS: strip_no_stop_itinerary_items,
        REACT_APP_SHOW_CANCELLATION_POLICY: show_cancellation_policy,
        REACT_APP_BREADCRUMBS_TEXT: breadcrumbs_text,
        REACT_APP_SHOW_PRICING_BREAKDOWN: show_pricing_breakdown,
        REACT_APP_CHECK_EMAIL_UNIQUENESS: check_email_uniqueness,
        REACT_APP_SHOW_PAGINATION: show_pagination,
        REACT_APP_PAGINATION_TYPE: pagination_type,
        REACT_APP_PAGINATION_COUNT: pagination_count,
        REACT_APP_SKIP_PAYMENT_STEP: skip_payment_step,
        REACT_APP_HEADER_SHOW_CURRENCY_CODE: header_show_currency_code,
        REACT_APP_HEADER_SHOW_LANGUAGE_PICTURE: header_show_language_picture,
        REACT_APP_HEADER_BACKGROUND_COLOR: header_background_color,
        REACT_APP_HEADER_TEXT_COLOR: header_text_color,
        REACT_APP_HEADER_TELEPHONE_NUMBER: header_telephone_number,
        REACT_APP_HEADER_LOGO_PICTURE: header_logo_picture,
        REACT_APP_FOOTER_BACKGROUND_COLOR: footer_background_color,
        REACT_APP_FOOTER_TEXT_COLOR: footer_text_color,
        REACT_APP_FOOTER_EMAIL_ADDRESS: footer_email_address,
        REACT_APP_FOOTER_ADDRESS: footer_address,
        REACT_APP_GFT_ADDITIONAL_TEXT: gft_additional_text,
        REACT_APP_DESTINATION_INPUT_PLACEHOLDER: destination_input_placeholder,
        REACT_APP_BIN_RESTRICTION: bin_restriction,
        REACT_APP_FAVICON: favicon,
        REACT_APP_HEADER_LOGO_HEIGHT: header_logo_height,
      } = acf;

      return {
        primary_color,
        secondary_color,
        text_primary_color,
        text_secondary_color,
        input_border_color,
        input_background_color,
        input_placeholder_color,
        input_text_color,
        input_focus_border_color,
        error_color,
        menu_background_color,
        menu_text_color,
        element_background_primary,
        element_background_secondary,
        element_background_dark,
        background_color,
        link_color,
        section_background_color,
        shadow_color,
        subtext_color,
        delimitter_line_color,
        svg_icons_primary,
        svg_icons_secondary,
        search_button_background,
        cruise_page,
        site_logo,
        header_background,
        footer_background,
        footer_font_color,
        api_username,
        api_password,
        api_agency,
        search_domain,
        token_domain,
        pos_domain,
        api_domain,
        app_language,
        payment_domain,
        date_format,
        api_language,
        authorize_api_login_id,
        authorize_client_key,
        table_view,
        api_agent,
        authorize_is_mine,
        authorize_is_test,
        payment_api_path,
        search_item_image_source,
        privacy_policy,
        terms_and_conditions,
        participant_release_agreement,
        supplemental_terms,
        guest_ticket_contract,
        subtract_gft,
        strip_no_stop_itinerary_items,
        show_cancellation_policy,
        breadcrumbs_text,
        show_pricing_breakdown,
        check_email_uniqueness,
        show_pagination,
        pagination_type,
        pagination_count,
        skip_payment_step,
        header_show_currency_code,
        header_show_language_picture,
        header_background_color,
        header_text_color,
        header_telephone_number,
        header_logo_picture,
        footer_background_color,
        footer_text_color,
        footer_email_address,
        footer_address,
        gft_additional_text,
        destination_input_placeholder,
        bin_restriction,
        favicon,
        header_logo_height,
      };
    }

    // Else return data object with environment values from EnvironmentAPI
    return {
      primary_color,
      secondary_color,
      text_primary_color,
      text_secondary_color,
      input_border_color,
      input_background_color,
      input_placeholder_color,
      input_text_color,
      input_focus_border_color,
      error_color,
      menu_background_color,
      menu_text_color,
      element_background_primary,
      element_background_secondary,
      element_background_dark,
      background_color,
      link_color,
      section_background_color,
      shadow_color,
      subtext_color,
      delimitter_line_color,
      svg_icons_primary,
      svg_icons_secondary,
      search_button_background,
      cruise_page,
      site_logo,
      header_background,
      footer_background,
      footer_font_color,
      api_username,
      api_password,
      api_agency,
      search_domain,
      token_domain,
      pos_domain,
      api_domain,
      app_language,
      payment_domain,
      date_format,
      api_language,
      authorize_api_login_id,
      authorize_client_key,
      table_view,
      api_agent,
      authorize_is_mine,
      authorize_is_test,
      payment_api_path,
      search_item_image_source,
      privacy_policy,
      terms_and_conditions,
      participant_release_agreement,
      supplemental_terms,
      guest_ticket_contract,
      subtract_gft,
      strip_no_stop_itinerary_items,
      show_cancellation_policy,
      breadcrumbs_text,
      show_pricing_breakdown,
      check_email_uniqueness,
      show_pagination,
      pagination_type,
      pagination_count,
      skip_payment_step,
      header_show_currency_code,
      header_show_language_picture,
      header_background_color,
      header_text_color,
      header_telephone_number,
      header_logo_picture,
      footer_background_color,
      footer_text_color,
      footer_email_address,
      footer_address,
      gft_additional_text,
      destination_input_placeholder,
      bin_restriction,
      favicon,
      header_logo_height,
    };
  },
);

// Action creator for initializing API token asynchronously
export const initApiToken = createAsyncThunk(
  "session/initApiToken",
  async (_, { getState, dispatch }) => {
    // Destructure username and password from environment state
    const { api_username: username, api_password: password } = (
      getState() as RootState
    ).environment;

    // Dispatch an API call to initialize API token
    const { data } = await dispatch(
      SessionApi.endpoints.initApiToken.initiate({
        username,
        password,
      }),
    );

    // If no data received, show an error toast message
    if (!data) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Wrong credentials",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }

    return data;
  },
);

// Action creator for initializing POS session asynchronously
export const initPosSession = createAsyncThunk(
  "session/initPosSession",
  async (_, { getState, dispatch }) => {
    // Destructure agent and agency from environment state
    const { api_agent: agent, api_agency: agency } = (getState() as RootState)
      .environment;

    // Dispatch an API call to initialize POS session
    const { data } = await dispatch(
      PosAPI.endpoints.initPosSession.initiate({ agent, agency }),
    );

    return data;
  },
);
