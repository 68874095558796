import classNames from "classnames";
import Flag from "react-world-flags";

// Import necessary dependencies including the path to the root route and the Logo component.
import { useTypedSelector } from "../../../store/store";
import formatPhoneNumber from "../../../utils/helpers/formatPhoneNumber";

import CustomLink from "../../shared/CustomLink";

import styles from "./index.module.scss"; // Styles for the Header component.

// The Header component is responsible for displaying the top part of the webpage, including the logo.
function Header() {
  const {
    header_telephone_number,
    header_show_currency_code,
    header_show_language_picture,
    header_logo_picture,
    header_logo_height,
  } = useTypedSelector((state) => state.environment);

  return (
    // Wrapper for the entire header content with specified styles class.
    <header className={styles.container}>
      {/* Inserting the Logo component and passing it the path to the root route along with the withText prop. */}
      {header_logo_picture && (
        <CustomLink
          to="/"
          className={classNames({
            [styles.logo_default]: header_logo_height === "",
          })}>
          <img
            src={header_logo_picture}
            style={
              header_logo_height
                ? { height: `${header_logo_height}px` }
                : undefined
            }
            alt="header logo"
          />
        </CustomLink>
      )}

      <div className={styles.flex}>
        <div className={styles.block}>
          {header_show_currency_code && (
            <p className={styles.currency}>{header_show_currency_code}</p>
          )}

          {header_show_language_picture && (
            <Flag
              code={header_show_language_picture}
              style={{ width: "30px", height: "auto" }}
            />
          )}
        </div>

        {header_telephone_number && (
          <a
            className={styles.telephone}
            href={`tel:${header_telephone_number}`}>
            {formatPhoneNumber(header_telephone_number)}
          </a>
        )}
      </div>
    </header>
  );
}

// Export the Header component for use in other parts of the application.
export default Header;
