import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";

interface ICollapsibleProps {
  expanded?: boolean;
  onExpand?: (value: boolean) => void;
  renderHeader: () => React.ReactNode;
  headerStyle?: string;
  bodyStyle?: string;
  className?: string;
}

/**
 * Collapsible component that can expand and collapse its content.
 *
 * @param {boolean} [expanded] - Determines whether the collapsible content is expanded or not.
 * @param {(value: boolean) => void} [onExpand] - Callback function invoked when the collapsible content is expanded or collapsed.
 * @param {() => React.ReactNode} renderHeader - Function to render the collapsible header.
 * @param {string} [headerStyle] - Additional CSS class for the collapsible header.
 * @param {string} [bodyStyle] - Additional CSS class for the collapsible body.
 * @param {string} [className] - Additional CSS class for the collapsible container.
 * @param {ForwardedRef<HTMLDivElement>} ref - Forwarded ref for accessing the Collapsible component's DOM element.
 * @returns {JSX.Element} Collapsible component JSX.
 */
const Collapsible = forwardRef(function Collapsible(
  {
    expanded,
    onExpand,
    renderHeader,
    children,
    headerStyle,
    bodyStyle,
    className,
  }: React.PropsWithChildren<ICollapsibleProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [internalExpanded, setInternalExpanded] = useState(!!expanded); // State to manage the internal expanded state of the collapsible
  const [contentHeight, setContentHeight] = useState<number>(0); // State to manage the height of the collapsible body

  const bodyRef = useRef<HTMLDivElement>(null); // Ref for the collapsible body

  // Function to handle expand/collapse event
  const handleExpand = (value: boolean) => () => {
    // Call the onExpand callback if provided
    if (onExpand) {
      onExpand(value);
    } else {
      // Update the internal expanded state if no callback provided
      setInternalExpanded(value);
    }

    // Calculate the height of the collapsible body after a delay
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (bodyRef.current) {
          setContentHeight(bodyRef.current.scrollHeight ?? 0);
        }
      });
    }, 250);
  };

  // Effect to calculate the height of the collapsible body when expanded
  useEffect(() => {
    if (internalExpanded) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          if (bodyRef.current) {
            setContentHeight(bodyRef.current.scrollHeight ?? 0);
          }
        });
      }, 250);
    }
  });

  // Effect to update the internal expanded state when prop changes
  useEffect(() => {
    if (expanded !== internalExpanded) {
      setInternalExpanded(!!expanded);
    }
  }, [expanded]);

  return (
    <div ref={ref} className={classNames(styles.container, className)}>
      {/* Header section of the collapsible */}
      <div
        className={classNames(styles.header, headerStyle)}
        onClick={handleExpand(!internalExpanded)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleExpand(!internalExpanded)();
          }
        }}
        role="button"
        tabIndex={0}
        aria-expanded={internalExpanded}>
        {renderHeader()}

        {/* Icon for expand/collapse */}
        <SvgIcon type={internalExpanded ? "minus" : "plus"} />
      </div>

      {/* Body section of the collapsible */}
      <div
        ref={bodyRef}
        className={classNames(styles.body, bodyStyle)}
        style={{
          maxHeight: internalExpanded ? `${contentHeight}px` : "0",
          padding: internalExpanded ? 20 : "0 20px",
        }}>
        {children}
      </div>
    </div>
  );
});

// Export the Collapsible component
export default Collapsible;
